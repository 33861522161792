<template>
  <section>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <div>
      <v-row align="center" justify="center" style="margin-top: 200px">
        <v-col cols="12" sm="8" md="5" v-if="forgot_password_state == 'login'" id="signin">
          <v-card class="elevation-12">
            <v-toolbar dark flat>
              <v-spacer />
              <v-toolbar-title>Forgot Password</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-overlay :value="overlay">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card-text>
              <v-form>
                <v-text-field v-model="user.uid" :rules="[rules.required, rules.uid]" prepend-icon="mdi mdi-email"
                  placeholder="Enter your login id" clearable @click="clearErrorMsg()" :hint="disabled == 1
                    ? 'noneditable'
                    : 'For example, admin@pu.com'
                    " :disabled="disabled == 1 ? true : false" required></v-text-field>

                <!-- <v-text-field type="number" id="enterOTP" v-if="hidden1" v-model="user.otp"
                  :rules="[v => !!v || 'OTP is required']" prepend-icon="mdi-email-open" placeholder="Enter your otp"
                  min="1" max="6" clearable hint="Please check your mail & enter otp." @change="btndisabled"
                  required></v-text-field> -->
                <!-- New Layout instead of page routing -->
                <!-- <v-text-field id="newpwd" v-if="hidden1" v-model="newpwd" prepend-icon="mdi-lock"
                  placeholder="Create new Password" clearable :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value = !value)" :type="value ? 'password' : 'text'"
                  hint="*Password must be at least 4 characters!" required></v-text-field> -->
                <!-- <v-text-field id="cnfmpwd" v-if="hidden1" v-model="cnfmpwd" prepend-icon="mdi-lock"
                  placeholder="Re Enter new Password" clearable :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value = !value)" :type="value ? 'password' : 'text'"
                  hint="Re enter your new password" required></v-text-field> -->
                <!-- <div class="error-message" v-if="error">
                  {{ error_message }}
                </div> -->
                <v-layout align-center justify-center v-if="hidden">
                  <v-btn class="mr-4" color="primary" @click="sendotp" :loading="send_otp_loading">
                    <v-icon>mdi-email-send-outline</v-icon>&nbsp;&nbsp; Send
                    OTP
                  </v-btn>
                </v-layout>
                <div class="error-message" v-if="error1">
                  {{ error_message1 }}
                </div>
                <!-- <v-layout align-center justify-center v-if="hidden1">
                      <v-btn class="mr-4" color="primary" @click="verifyotp" :disabled="btnDisabled == true ? true : false">Verify OTP</v-btn>
                  </v-layout>-->
                <!-- New layout instead of page routing -->
                <v-layout align-center justify-center v-if="hidden1">
                  <v-btn class="mr-4" color="primary" @click="changePassword"
                    :disabled="btnDisabled == true ? true : false">Change Password</v-btn>
                </v-layout>
              </v-form>
            </v-card-text>
            <router-link to="/" style="text-decoration: none" title="Back">
              <v-icon large color="grey darken">mdi-chevron-left-circle</v-icon>
            </router-link>
          </v-card>
        </v-col>
        <v-col cols="12" lg="5" id="verifyotp" v-if="forgot_password_state == 'verify_otp'">
          <v-card class="elevation-12">
            <v-toolbar dark flat>
              <v-spacer />
              <v-toolbar-title>OTP Sent On Your Email</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <form>
                <form>
                  <v-container>
                    <v-text-field prepend-icon="mdi-lock" v-model="otp" color="primary" variant="underlined"
                      placeholder="Enter Your OTP" label="OTP" :append-icon="otpvisibal ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="otpvisibal ? 'text' : 'password'" @click:append="otpvisibal = !otpvisibal"
                      required></v-text-field>
                  </v-container>
                  <v-row no-gutters justify="center">
                    <v-btn color="primary" class="rounded-pill" :loading="verify_otp_loading" @click="Verifyotp()">Verify
                      OTP</v-btn>

                    <v-btn color="primary" class="rounded-pill ml-3" @click="redirecthome()">Cancel</v-btn>
                  </v-row>
                  <br />
                </form>
              </form>
            </v-card-text>

          </v-card>

        </v-col>

        <v-col cols="12" lg="5" id="resetpwd" v-if="forgot_password_state == 'reset_password'">
          <v-responsive class="d-flex fill-height mt-n5">

            <v-col cols="12" class="d-flex align-center justify-center">
              <span class="text-h6 font-weight-bold mt-5">
                <span class="blue-theme">Reset </span><span class="yellow-theme">Password</span></span>
            </v-col>
            <div>
              <center>
                <h3><span style="color: grey"></span></h3>
              </center>
              <v-card class="pa-2 ma-2">
                <v-card-title>Password Criteria</v-card-title>
                <v-row no-gutters justify="center" class="ml-3">
                  <v-col cols="12" sm="10" md="10">
                    <div style="color: grey">
                      ● Password Length should Be Greter Than 8 Digit
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <div>
                      <v-icon v-if="password_length" color="green">
                        mdi mdi-check</v-icon>
                      <v-icon v-else color="red">
                        mdi mdi-window-close</v-icon>

                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center" class="ml-3">
                  <v-col cols="12" sm="10" md="10">
                    <div style="color: grey">
                      ● Password And Confirm Password Should Be Same.
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <div>
                      <div>
                        <v-icon v-if="password_same" color="green">
                          mdi mdi-check</v-icon>
                        <v-icon v-else color="red">
                          mdi mdi-window-close</v-icon>

                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center" class="ml-3">
                  <v-col cols="12" sm="10" md="10">
                    <div style="color: grey">
                      ● Password Should Have At Least 1 Upper Case Letter
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <div>
                      <div>
                        <v-icon v-if="password_upercase" color="green">
                          mdi mdi-check</v-icon>
                        <v-icon v-else color="red">
                          mdi mdi-window-close</v-icon>

                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center" class="ml-3">
                  <v-col cols="12" sm="10" md="10">
                    <div style="color: grey">
                      ● Password Should Have At Least 1 special character
                    </div>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <div>
                      <v-icon v-if="password_special_case" color="green">
                        mdi mdi-check</v-icon>
                      <v-icon v-else color="red">
                        mdi mdi-window-close</v-icon>

                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <form>
              <v-container>
                <v-text-field color="primary" variant="underlined" prepend-icon="mdi-lock" v-model="password"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword" @input="password_criteria()" required autocomplete="off"
                  placeholder="Enter Your New Password" label="New Password"></v-text-field>
                <v-text-field v-model="cpassword" :append-icon="confirmpassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="confirmpassword ? 'text' : 'password'" @click:append="confirmpassword = !confirmpassword"
                  @input="password_criteria()" color="primary" variant="underlined" prepend-icon="mdi-lock"
                  placeholder="Enter Your Confirm Password" label="Confirm Password" required
                  autocomplete="off"></v-text-field>
              </v-container>
              <v-row no-gutters justify="center">
                <v-btn color="primary" :disabled="disable_reset_btn" class="rounded-pill" @click="resetpwd()">Reset
                  Password</v-btn>

                <v-btn color="primary" class="rounded-pill ml-3" @click="redirecthome()">Cancel</v-btn>
              </v-row>
              <br />
            </form>
          </v-responsive>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Header from "../components/shared/Header";


export default {
  components: {
    Header: Header,
  },
  data() {

    return {
      disable_reset_btn: true,
      forgot_password_state: "login",
      password_length: false,
      password_same: false,
      password_upercase: false,
      send_otp_loading: false,
      password_special_case: false,
      verify_otp_loading: false,
      user: {
        uid: "",

        otp: ""
      },
      rules: {
        required: value => !!value || "Required.",
        uid: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      hidden: true,
      hidden1: false,
      disabled: 0,
      btnDisabled: true,
      otpvisibal: false,

      confirmpassword: false,
      showPassword: false,
      message: "",
      successMessage: "",
      dbotp: "",
      error: false,
      error_message: "",
      error1: false,
      error_message1: "",
      overlay: false,
      snackbar_msg: "",
      color: "",
      snackbar: false,
      newpwd: "",
      cnfmpwd: "",
      value: String,
      password: "Password",
      email: "",
      password: "",
      cpassword: "",
    };
  },
  methods: {
    redirecthome() {
      this.$router.push({
        name: "login",
      });
    },
    password_criteria() {
      if (this.password.length >= 8 && this.cpassword.length >= 8) {
        this.password_length = true;
      } else this.password_length = false;
      const specialCharacters = /[^A-Za-z0-9]/;
      if (
        specialCharacters.test(this.password) &&
        specialCharacters.test(this.cpassword)
      ) {
        this.password_special_case = true;
      } else this.password_special_case = false;
      const specialCharacters1 = /(.*[A-Z].*)/;
      if (
        specialCharacters1.test(this.password) &&
        specialCharacters1.test(this.cpassword)
      ) {
        this.password_upercase = true;
      } else this.password_upercase = false;
      if (this.password == this.cpassword && this.password != '' && this.cpassword != '') this.password_same = true;
      else this.password_same = false;

      if (this.password_length && this.password_same && this.password_upercase && this.password_special_case) {
        this.disable_reset_btn = false;
      }
      else {
        this.disable_reset_btn = true;
      }

    },
    sendotp() {
      if (this.user.uid == "" ? "" : this.reg.test(this.user.uid)) {
        if (this.perormEmailFormValidation()) {
          //console.log("I am in If block :: " + this.user.uid);
          this.user.dns = window.location.hostname;
          // this.overlay = true;
          this.send_otp_loading = true;
          axios
            .post("/login/send_forgot_password_email", this.user)
            .then(res => {
              window.console.log("res" + res.data.msg);
              if (res.data.status == "200") {
                this.forgot_password_state = "verify_otp";

                //console.log(res.data)
                // this.overlay = false;
                this.send_otp_loading = false;
                this.error_message = "";
                //this.successMessage=true;
                this.showSnackbar(
                  "#4caf50",
                  "OTP has been sent on your Email..."
                ); // show snackbar on error
                //this.successMessage = "OTP has been sent on your Email";
                localStorage.setItem("EPS-uid", res.data.uid);
                this.hidden = false;
                this.hidden1 = true;
                this.disabled = (this.disabled + 1) % 2;
                this.dbotp = res.data.otp;


              } else if (res.data.status == "404") {
                this.send_otp_loading = false;
                this.showSnackbar(
                  "red",
                  res.data.msg
                );
              }
              else if (res.data.status == "401") {
                this.send_otp_loading = false;
                this.showSnackbar(
                  "red",
                  res.data.msg
                );
              }
              else {
                this.error = true;
                this.error_message = "Email address not found!";
                // this.overlay = false;
                this.send_otp_loading = false;

              }


            })
            .catch(error => {
              // this.overlay = true;
              window.console.log(error);
              this.message = "Something went wrong";
            })
            .finally(() => {
              this.overlay = false;
            });
        } else {
          //console.log("I am in else block :: " + this.user.uid);
          this.error = true;
          this.error_message = "Please Enter Valid Email ID..";
        }
      } else {
        //console.log("I am in else block :: " + this.user.uid);
        this.error = true;
        this.error_message = "Please Enter Valid Email ID..";
      }
    },

    Verifyotp() {
      if (!this.otp) {
        this.showSnackbar(
          "red",
          "Please Enter OTP."
        );

        return;
      }

      this.verify_otp_loading = true;
      const data = {
        uid: this.user.uid,
        otp: this.otp,
      };
      axios.post("login/verify_otp_forgot_password", data).then((res) => {
        if (res.data.status == "200") {
          this.forgot_password_state = "reset_password";
          // this.$refs.snackbar.showSnackbar(res.data.msg, "green");
          this.showSnackbar(
            "green",
            res.data.msg
          ); // show snackbar on error
          this.verify_otp_loading = false;
        } else {
          this.showSnackbar(
            "red",
            res.data.msg
          );
          //this.$refs.snackbar.showSnackbar(res.data.msg, "red");

          this.verify_otp_loading = false;
        }
      });
    },

    resetpwd() {
      if (!this.password) {
        this.$refs.snackbar.showSnackbar("Please Enter Password", "red");
        return;
      }

      if (!this.cpassword) {
        this.$refs.snackbar.showSnackbar(
          "Please Enter Confirm Password",
          "red"
        );
        return;
      }
      if (this.password.length < 8) {
        this.$refs.snackbar.showSnackbar(
          "Password Length Should Be Grater Than 8 Character",
          "red"
        );
        return;
      }
      if (this.cpassword.length < 8) {
        this.$refs.snackbar.showSnackbar(
          "Password Length Should Be Greater Than 8 Character",
          "red"
        );
        return;
      }
      if (this.cpassword != this.password) {
        this.$refs.snackbar.showSnackbar(
          "Password And Confirm Password Should Be Same.",
          "red"
        );
        return;
      }
      this.reset_password_loading = true;
      const data = {
        uid: this.user.uid,
        password: this.password,
        confirm_password: this.cpassword,
      };
      axios.post("login/update_forgot_password", data).then((res) => {
        if (res.data.status == "200") {
          //   this.forgot_password_state = "login";
          this.showSnackbar(
            "green",
            res.data.msg
          );

          this.reset_password_loading = false;
          this.$router.push({
            name: "login",
          });
        } else {
          this.showSnackbar(
            "red",
            res.data.msg
          );

          this.reset_password_loading = false;
        }
      });
    },

    // verifyotp() {
    //   if (this.perormOtpFormValidation()) {
    //     //console.log("I am in If block :: " + this.user.uid + " :: "+  this.user.otp  + " :: " + this.dbotp);
    //     this.overlay = true;
    //     axios
    //       .post("/login/verifyForgotPasswordOTP", this.user)
    //       .then(res => {
    //         //console.log(res.data);
    //         if (res.data.msg == "YES") {
    //           this.overlay = false;
    //           this.showSnackbar("#4caf50", "OTP verified successfully"); // show snackbar on error
    //           //console.log(res.data);
    //           this.hidden = false;
    //           this.hidden1 = false;
    //         } else if (res.data.msg == "NO") {
    //           this.overlay = false;
    //           this.showSnackbar("#b71c1c", "Wrong OTP! Please try again..."); // show snackbar on error
    //           document.getElementById("enterOTP").value = "";
    //         }
    //       })
    //       .catch(error => {
    //         window.console.log(error);
    //         this.message = "Something went wrong";
    //       })
    //       .finally(() => {
    //         "use strict";
    //         var overlay = false;
    //       });
    //     //
    //     // if(this.user.otp == this.dbotp) {
    //     //   this.hidden = false;
    //     //   this.$router.push("/change-password");
    //     //   //window.location="/change-password";
    //     // } else {
    //     //   this.message = "OTP not Match!!!";
    //     //   this.error1=true;
    //     //   this.error_message1='Please Enter Valid OTP..';
    //     // }
    //   } else {
    //     //console.log("I am in else block :: " + this.user.uid);
    //     this.error1 = true;
    //     this.error_message1 = "Please Enter OTP..";
    //   }
    // },
    perormEmailFormValidation() {
      if (this.user.uid == "") {
        return false;
      } else {
        return true;
      }
    },
    perormOtpFormValidation() {
      if (this.user.otp == "" && this.user.uid == "") {
        return false;
      } else {
        return true;
      }
    },
    btndisabled() {
      this.btnDisabled = false;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    changePassword() {
      //console.log("in changePassword");
      if (this.perormOtpFormValidation()) {
        if (this.checkBlankPwdFields()) {
          if (this.matchPwdFields()) {
            if (this.newpwd.length < 4) {
              this.showSnackbar(
                "#b71c1c",
                "The password must be at least 4 characters!"
              );
            } else if (this.newpwd.length > 12) {
              this.showSnackbar(
                "#b71c1c",
                "Too long! the password must not exceed 12 characters!"
              );
            } else {
              const data = {
                newpwd: this.newpwd,
                uid: this.user.uid,
                otp: this.user.otp
              };
              // console.log(data);
              this.overlay = true;
              axios
                .post("/login/verifyOTPandChangePassword", data)
                .then(res => {
                  //console.log(res.data);
                  if (res.data.msg == "200") {
                    this.overlay = false;
                    //this.showSnackbar("#4caf50", "Password Changed Succussfully"); // show snackbar on error
                    this.sleep(2000);
                    alert("Password changed succussfully...");
                    if (res.data.uid == "superadmin@easypariksha.com") {
                      //window.alert("res.data.uid: "+res.data.uid)
                      this.$router.push("/eps-admin");
                    } else {
                      //window.alert("res.data.uid: "+res.data.uid)
                      this.$router.push("/");
                    }
                  } else if (res.data.msg == "ONM") {
                    this.overlay = false;
                    this.showSnackbar(
                      "#b71c1c",
                      "Wrong OTP! Please try again..."
                    ); // show snackbar on error
                    document.getElementById("enterOTP").value = "";
                    // console.log(res.data);
                  }
                });
            }
          } else {
            this.overlay = false;
            this.showSnackbar("#b71c1c", "Password Doesn't match!"); // show snackbar on error
            document.getElementById("newpwd").value = "";
            document.getElementById("cnfmpwd").value = "";
          }
        } else {
          this.overlay = false;
          this.showSnackbar("#b71c1c", "Please provide Password!"); // show snackbar on error
          document.getElementById("newpwd").value = "";
          document.getElementById("cnfmpwd").value = "";
        }
      }
    },
    checkBlankPwdFields() {
      if (this.newpwd == "" && this.cnfmpwd == "") {
        return false;
      } else {
        return true;
      }
    },
    matchPwdFields() {
      if (this.newpwd == this.cnfmpwd) {
        return true;
      } else {
        return false;
      }
    },
    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },
    clearErrorMsg() {
      this.error_message = "";
    }
  },
  mounted() {
    this.message = localStorage.getItem("login-page-msg");
  }
};
</script>
<style scoped>
.pull-right {
  position: relative;
  text-align: center;
}

.error-message {
  color: red;
  font-size: 14px;
  font-weight: 900;
  padding-left: 7%;
}

.header {
  background: cadetblue !important;
}
</style>
